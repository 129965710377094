.cfra-ag-grid {
    span[ref='eSortOrder'] {
        display: none !important;
    }

    .ag-grid-selection-column {
        --ag-cell-horizontal-padding: 10px;
    }

    .ag-theme-alpine {
        --ag-header-background-color: #fff;
        --ag-header-foreground-color: #57626a;
        --ag-cell-horizontal-padding: 29px;
        --ag-font-size: 14px;
        --ag-borders: solid 0px;
    }

    .ag-center-cols-viewport,
    .ag-header-viewport {
        background-attachment: local, local, scroll, scroll !important;
        background-color: white !important;
        background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100% !important;
        background-repeat: no-repeat !important;
        background-position: left center, right center, left center, right center !important;
        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.10), rgba(255, 255, 255, 0)) !important;
        z-index: 1;
        overflow-y: hidden;
    }

    .ag-grid-cfra-watchlist-icons {
        --ag-icon-font-code-checkbox-checked: url('../../assets/images/ScreenerCheckedIcon.svg');
        --ag-icon-font-code-checkbox-indeterminate: url('../../assets/images/ScreenerUncheckedIcon.svg');
        --ag-icon-font-code-checkbox-unchecked: url('../../assets/images/ScreenerUncheckedIcon.svg');
        --ag-cell-horizontal-padding: 10px;
        --ag-range-selection-border-style: none;

        .ag-checkbox-input-wrapper {
            width: 0;
        }
    }

    .ag-grid-cfra-watchlist-icons-reverse {
        --ag-icon-font-code-checkbox-checked: url('../../assets/images/ScreenerUncheckedIcon.svg');
        --ag-icon-font-code-checkbox-indeterminate: url('../../assets/images/ScreenerUncheckedIcon.svg');
        --ag-icon-font-code-checkbox-unchecked: url('../../assets/images/ScreenerCheckedIcon.svg');
        --ag-cell-horizontal-padding: 10px;
        --ag-range-selection-border-style: none;

        .ag-checkbox-input-wrapper {
            width: 0;
        }
    }

    .ag-header-row {
        font-family: GraphikMedium;
        font-weight: 500;
    }

    .ag-header {
        border-top: 1px solid #74828d;
        border-bottom: 1px solid #74828d;
    }

    .ag-row {
        .company-details-url {
            color: #007bb8;
            text-decoration: none;
        }

        font-family: GraphikRegular;
        color: #002b5a;
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .ag-header-cell-text {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
    }

    .ag-right-aligned-header .ag-header-cell-text {
        text-align: right;
    }

    .ag-root ::-webkit-scrollbar {
        width: 15px;
    }

    .ag-root ::-webkit-scrollbar-button {
        height: 0px;
    }

    .ag-root ::-webkit-scrollbar-thumb {
        min-height: 51%;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: #bdbdbd;
        border-radius: 25px;
    }
}
